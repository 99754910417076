import { Link, useLocation, type LinkProps } from "@remix-run/react"
import { forwardRef } from "react"

type BackAwareLinkProps = Omit<LinkProps, "state">

const BackAwareLink = forwardRef<HTMLAnchorElement, BackAwareLinkProps>(
	function BackAwareLink(props, ref) {
		const { pathname, search } = useLocation()
		return <Link {...props} state={pathname + search} ref={ref} />
	},
)

interface BackLinkProps extends Omit<LinkProps, "to"> {
	fallback: string
}

const BackLink = forwardRef<HTMLAnchorElement, BackLinkProps>(function BackLink(
	{ fallback, ...props },
	ref,
) {
	const { state: backLink } = useLocation()
	return <Link ref={ref} to={backLink ?? fallback} {...props} />
})

export { BackLink, BackAwareLink }
